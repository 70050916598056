<template>
  <div :style="{ background: micrositeColor }" class="flexpers">
    <v-container style="margin-top: auto; margin-bottom: auto">
      <div>
        <v-btn
          color="#fff"
          v-if="backId !== ''"
          class="ml-4"
          icon
          :to="{
            path: `/micrositio-membership/${backId}`,
            query: { step: 'members' },
          }"
        >
          <v-icon> mdi-arrow-left </v-icon> Volver
        </v-btn>
        <br />
        <v-card rounded="xl">
          <v-row class="my-auto" style="background: #fff">
            <v-col
              style="background: #f8fafc"
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'"
              class="px-6 py-4 rounded-xl"
            >
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-btn
                    style="float: left"
                    :color="
                      homologateStatusWithColor(credInfo.validationStatus).color
                    "
                    width="auto"
                    :loading="load"
                    class="font-weight-black mx-auto text-capitalize font-weight-bold rounded-pill"
                  >
                    {{
                      homologateStatusWithColor(credInfo.validationStatus)
                        .status
                    }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0 pb-4 text-center">
                  <v-avatar color="#fbb406" size="100" class="mx-auto mt-4">
                    <span
                      v-if="
                        credInfo.profilePicture == 'NOT_ASSIGNED' &&
                        (!credInfo.directoryData ||
                          !credInfo.directoryData.logo)
                      "
                      style="color: white; font-weight: bold; font-size: 20px"
                      >{{ profile }}</span
                    >
                    <v-img
                      v-else
                      :src="
                        !credInfo.directoryData.logo || credInfo.profilePicture
                      "
                    ></v-img>
                  </v-avatar>
                  <v-card-title
                    class="px-0 py-1 align-center justify-center"
                    style="
                      font-size: 1.2rem;
                      word-break: break-word;
                      font-weight: 600;
                    "
                    >{{
                      credInfo.directoryData.name ||
                      credInfo.name + " " + credInfo.firstLastname
                    }}</v-card-title
                  >
                  <!-- <div class="px-0 upercase py-1"> {{ credInfo.branch.company || "N/D" }}  {{ cred.company }}</div>
                  <div class="pb-3"> <span class="px-0 font-weight-black upercase "> MIEMBRO DESDE</span> - {{getbyFindIndex(asociado,'Miembro desde')}}</div> -->
                  <hr class="hr_color mt-5" />
                </v-col>
                <v-col cols="12">
                  <div class="d-flex py-1">
                    <v-icon color="black" class="pr-2"> mdi-domain </v-icon>
                    <span class="h5">{{
                      credInfo.directoryData.company ||
                      credInfo.company ||
                      "N/D"
                    }}</span>
                  </div>
                  <div class="d-flex py-1">
                    <v-img
                      src="@/assets/images/landing/memberLanding/ico-telefono.svg"
                      max-width="25"
                      contain
                      class="mr-2"
                    />
                    <span class="h5">
                      {{
                        credInfo.directoryData.phone || credInfo.phone || "N/D"
                      }}
                    </span>
                  </div>
                  <div class="d-flex py-1">
                    <v-img
                      src="@/assets/images/landing/memberLanding/ico-email.svg"
                      max-width="25"
                      contain
                      class="mr-2"
                    />
                    <span class="h5">{{
                      credInfo.directoryData.email || credInfo.email
                    }}</span>
                  </div>

                  <div class="d-flex py-1">
                    <v-icon color="black" class="pr-2">
                      mdi-link-variant
                    </v-icon>
                    <span class="h5">
                      <a
                        v-if="credInfo.directoryData.url"
                        :href="formatUrl(credInfo.directoryData.url)"
                        target="_blank"
                        rel="noopener noreferrer"
                        style="color: inherit; text-decoration: underline"
                      >
                        {{ credInfo.directoryData.url }}
                      </a>
                      <span v-else>N/D</span>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              style="background: #fff"
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'"
              class="px-6 py-4 rounded-xl"
            >
              <v-row class="pr-5">
                <v-col :cols="12" width="100%" elevation="0">
                  <v-card-title class="px-0 font-weight-black"></v-card-title>
                  <v-row :cols="12" width="100%" elevation="0">
                    <v-col
                      :cols="$vuetify.breakpoint.smAndDown ? '12' : '3'"
                      v-for="(field, i) in credInfo.memberData"
                      :key="i"
                      class="pt-3 pb-2"
                      :class="showField(field) ? '' : 'd-none'"
                    >
                      <p>
                        <span style="font-weight: 800">{{ field.name }}: </span>
                        <br />
                        <small>{{ field.value || "N/D" }}</small>
                      </p>
                    </v-col>
                  </v-row>
                  <hr class="hr_color" />
                </v-col>

                <v-col cols="12" class="text-end">
                  <span class="px-0 font-weight-black upercase pr-6"
                    >MIEMBRO DE</span
                  >
                  <v-avatar size="100" class="hr_color mx-auto">
                    <span
                      v-if="
                        !credInfo.headOffice ||
                        credInfo.headOffice.profilePicture == 'NOT_ASSIGNED'
                      "
                      style="color: white; font-weight: bold; font-size: 20px"
                      >{{ profile }}</span
                    >
                    <v-img
                      v-else
                      :src="credInfo.headOffice.profilePicture"
                    ></v-img>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapMutations, mapState } from "vuex";
import { formatDate } from "@/utils/dates";
import { homologateStatusWithColor } from "@/common/hook/useHelper.js";

export default {
  data() {
    return {
      micrositeColor: {},
      affiliationId: "",
      cred: {},
      credInfo: {},
      personalInfo: [],
      directions: [],
      asociado: [],
      profile: "",
      backId: "",
      load: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    isAmpi() {
      const micrositeId = this.$route.query.micrositeId;
      //dev ampi: 62d707d0cdc3d0001b08cb84
      const ampi1 = "62d707d0cdc3d0001b08cb84";
      const ampi2 = "62d707d0cdc3d0001b08cb84";
      return micrositeId === ampi1 || micrositeId === ampi2;
    },
  },
  methods: {
    ...mapActions("microsite", ["getMemberInformation"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    homologateStatusWithColor,
    formatUrl(url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        return "https://" + url; // O usa "http://" si es necesario
      }
      return url;
    },

    async fetchMicrositeConfig() {
      try {
        const response = await this.axios.get(
          `/microsite/affiliation/${this.affiliationId}`
        );
        if (response && response.data) {
          this.micrositeColor =
            response.data.affiliation.micrositeConfiguration.primaryColor;
        } else {
          console.error("Respuesta inesperada de la API", response);
        }
      } catch (error) {
        if (error.response) {
          console.error(
            "Error en la respuesta de la API:",
            error.response.status,
            error.response.data
          );
        } else {
          console.error("Error en la solicitud:", error.message);
        }
      }
    },

    showField(field) {
      if (field.showInDataSheet != undefined) {
        if (field.fieldType == "DOC") {
          return false;
        } else {
          return field.showInDataSheetByUser && field.showInDataSheet;
        }
      } else {
        if (field.fieldType == "DOC") {
          return false;
        } else {
          if (this.isAmpi) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
  },
  async mounted() {
    this.loading();
    this.load = true;

    this.credInfo = (
      await this.getMemberInformation(this.$route.params.memberId)
    ).data;
    this.affiliationId = this.user?.affiliation?.national?.details[0]?.affiliationType?.affiliation || this.credInfo?.memberActiveItems[0]?.affiliation;
    await this.fetchMicrositeConfig();

    this.loaded();
    this.load = false;
  },
  created() {
    if (this.$route.query.micrositeId) {
      this.backId = this.$route.query.micrositeId;
    }
  },
};
</script>


<style scoped>
.flexpers {
  display: flex;
  overflow-y: auto;
  height: 100vh;
}
.v-main__wrap {
  background: aliceblue !important;
}
.hr_color {
  border: 1px solid #c4c6c8;
}
.upercase {
  text-transform: uppercase !important;
}
</style>